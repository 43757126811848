<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-material-card
      hover-reveal
      color="primary"
      class="px-5 py-3"
    >
      <template v-slot:reveal-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              color="light"
              icon
              v-on="on"
              @click="getLaunches()"
            >
              <v-icon
                color="light"
              >
                mdi-refresh
              </v-icon>
            </v-btn>
          </template>

          <span>Mettre à jour</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              color="light"
              icon
              v-on="on"
              @click="newLaunch()"
            >
              <v-icon
                color="light"
              >
                mdi-plus-circle-outline
              </v-icon>
            </v-btn>
          </template>

          <span>Nouveau lancement</span>
        </v-tooltip>
      </template>
      <template v-slot:heading>
        <div class="display-2 font-weight-light">
          Lancements du Scraper
        </div>

        <div class="subtitle-1 font-weight-light">
          Planifiés pour fonctionner en continu
        </div>
      </template>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="launches"
          :loading="loading"
          loading-text="Chargement... veuillez patienter"
          no-data-text="Pas de données disponibles"
          :options.sync="optionsLaunches"
          :server-items-length="totalLaunches"
          :multi-sort="true"
          :footer-props="{
            itemsPerPageText: 'Lignes par page:',
          }"
        >
          <template v-slot:item.params.mots="{ item }">
            <v-tooltip
              v-if="item.error"
              bottom
            >
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  color="red"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>

              <span>{{ item.error }}</span>
            </v-tooltip>
            {{ item.params.mots }}
          </template>
          <template v-slot:item.lastLaunch="{ item }">
            {{ moment(item.lastLaunch).calendar() }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editLaunch(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteLaunch(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <base-material-overlay-alert
      v-model="alertError"
      :type="alertType"
    >
      {{ alertErrorText }}
    </base-material-overlay-alert>
    <base-material-snackbar
      v-model="alertSuccess"
      right
      top
      type="success"
    >
      {{ alertSuccessText }}
    </base-material-snackbar>
    <base-confirm ref="confirm" />
  </v-container>
</template>

<script>
  import LaunchesService from '@/services/launches.service'

  import moment from 'moment'
  moment.locale('fr')

  export default {
    name: 'DashboardLaunch',
    data: () => ({
      alertType: 'error',
      alertError: false,
      alertErrorText: 'Une erreur est survenue',
      alertSuccess: false,
      alertSuccessText: 'Effacé avec succès',
      optionsLaunches: {},
      totalLaunches: 0,
      loading: false,
      headers: [
        {
          sortable: true,
          text: 'Recherche de requête',
          value: 'params.mots',
        },
        {
          sortable: true,
          text: 'Dernier lancement',
          value: 'lastLaunch',
          align: 'right',
        },
        {
          sortable: true,
          text: 'Maximum de crédits quotidiens',
          value: 'maxDailyCredits',
          align: 'right',
        },
        {
          sortable: true,
          text: 'Crédits quotidiens utilisés',
          value: 'dailyCreditsUsed',
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      launches: [],

    }),
    watch: {
      optionsLaunches: {
        handler () {
          this.getLaunches()
        },
        deep: true,
      },
    },
    mounted () {
      this.getLaunches()
      this.$root.$confirm = this.$refs.confirm.open
    },
    methods: {
      moment,
      newLaunch () {
        this.$router.push('/launch/new')
      },
      editLaunch (item) {
        this.$router.push({ path: `/launch/edit/${item._id}` })
      },
      deleteLaunch (item) {
        this.$refs.confirm.open('Supprimer élément', 'Es-tu sûr de vouloir le supprimer?', { color: 'red' }).then((confirm) => {
          confirm && LaunchesService.delete(item._id).then(response => {
            this.alertSuccess = true
            const index = this.launches.indexOf(item)
            this.launches.splice(index, 1)
          })
            .catch(error => {
              const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString()
              // console.log(error)
              if (error.response && error.response.data && error.response.data.type) {
                this.alertType = error.response.data.type
              } else {
                this.alertType = 'error'
              }
              this.alertErrorText = message
              this.alertError = true
            })
        })
      },
      getLaunches () {
        this.launched = true
        LaunchesService.getLaunches(this.optionsLaunches).then(response => {
          this.launches = response.data.launches
          this.totalLaunches = response.data.totalLaunches
        })
          .catch(error => {
            const message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString()
            // console.log(error)
            if (error.response && error.response.data && error.response.data.type) {
              this.alertType = error.response.data.type
            } else {
              this.alertType = 'error'
            }
            this.alertErrorText = message
            this.alertError = true
          })
      },
    },
  }
</script>
