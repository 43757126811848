import authHeader from './auth-header'

import axios from 'axios'

const $http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

const API_URL = '/launches/'

class LaunchesService {
  /**
   * Get paginated launches
   *
   * @param {Object} data
   *                 data.itemsPerPage {Number} Limit items per page
   *                 data.page {Number} Current page number
   *                 data.sortBy {Array} Sorts order
   *                 data.sortDesc {Array} If sorts must be descending
   *
   * @returns {Object} response:
   *                   response.data.launches {Object} Launches
   *                   response.data.totalLaunches {Object} Total launches
   */
  getLaunches (data) {
    return $http.get(API_URL + 'all', { headers: authHeader(), params: data })
  }

  /**
   * Get launch by given id
   *
   * @param {ObjectId} launch id
   *
   * @returns {Object} response:
   *                   response.data.params {Object} launch
   */
  getLaunch (id) {
    return $http.get(API_URL + 'get/' + id, { headers: authHeader() })
  }

  /**
   * Save launch with given parameters
   *
   * @param {Object} data:
   *                 data.actions {String} JSON with actions
   *                 data.maxDailyCredits {Number} Max daily credits
   *                 data.mots {String}
   *                 data.Poste: {Array}
   *                 data.Region: {String}
   *                 data.Region_m: {String}
   *                 data.Dossier: {Array}
   *                 data.typecv: {Array}
   *                 data.Population: {Array}
   *                 data.Population_m: {String}
   *                 data.CVrecent: {Boolean}
   *                 data.tjm_min: {Number}
   *                 data.sal_min: {Number}
   *
   * @returns {Object} response:
   *                   response.data.message {String} Message to display
   *                   response.data.type {String} error | success | warning | info
   */
  save (data) {
    if (data._id) {
      // Update
      return $http.put(API_URL + 'save', { headers: authHeader(), params: data })
    } else {
      // Create new
      return $http.post(API_URL + 'save', { headers: authHeader(), params: data })
    }
  }

  /**
   * Delete launch by given id
   *
   * @param {ObjectId} launch id
   */
  delete (id) {
    return $http.delete(API_URL + 'delete/' + id, { headers: authHeader() })
  }
}

export default new LaunchesService()
